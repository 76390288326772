import React from 'react'
import {Container} from './styles'

interface Fotos {
  id: number
  titulo: string
  descricao: string
  album: string
  foto: string
}

interface DadosProps {
  fotos: Record<number, unknown>
  codigo: number
}

const Fotos: React.FC<DadosProps> = props => {
  return (
    <Container>
      <h2>Fotos</h2>
      {props.fotos[0] && (
        <ul className="fotos">
          {Object.values(props.fotos[0] as Fotos).map((element, idx) => (
            <li key={idx}>
              <img src={`/album/${props.codigo}/thumbs/${element.foto}?idx=${Math.random()}`} alt={element.titulo} />
            </li>
          ))}
        </ul>
      )}
    </Container>
  )
}

export default Fotos
