import {useField} from '@unform/core'
import React, {InputHTMLAttributes, useCallback, useEffect, useRef, useState} from 'react'
import {FiAlertCircle} from 'react-icons/fi'
import {Container, Erro, Titulo} from './styles'

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string
}

const Input: React.FC<PropsInput> = ({name, ...rest}) => {
  const {fieldName, defaultValue, error, registerField} = useField(name)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocus, setIsFocus] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const fnFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  const fnFilled = useCallback(() => {
    setIsFilled(!!inputRef.current?.value)
    setIsFocus(false)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <Container isFocus={isFocus} isFilled={isFilled} isErrors={!!error}>
      {inputRef.current?.type == 'file' ? (
        <span>{inputRef.current?.title}</span>
      ) : (
        <Titulo isFilled={isFilled} isErrors={!!error}>
          {inputRef.current?.title}
        </Titulo>
      )}
      <input defaultValue={defaultValue} ref={inputRef} type="text" {...rest} onFocus={fnFocus} onBlur={fnFilled} />
      {error && (
        <Erro title={error}>
          <FiAlertCircle size={20} />
        </Erro>
      )}
    </Container>
  )
}

export default Input
