import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {AiOutlineTable} from 'react-icons/ai'
import {BiUnlink} from 'react-icons/bi'
import {BsClockHistory} from 'react-icons/bs'
import {GoCalendar} from 'react-icons/go'
import {RiDownloadCloud2Line} from 'react-icons/ri'
import api from '../../services/api'
import {Ano, Container, Mes, Meses, Periodo} from './styles'

interface Periodo {
  id: string
  mes: string
  arquivo: string
  arquivo_xls: string
}

interface Dados {
  [dados: string]: Record<string, Periodo>
}

interface ItemMenu {
  focus: boolean
  selected: string
  positionLeft: number
  positionTop: number
}

const Historico: React.FC = () => {
  const [historico, setHistorico] = useState<Dados>()

  const [isFocus, setIsFocus] = useState<ItemMenu>({focus: false, selected: '', positionLeft: 0, positionTop: 0})

  const getHistorico = useCallback(async () => {
    const anp = await api.get('historico.php')
    setHistorico(anp.data)
  }, [])

  const fnClick = useCallback(
    (id: string, event) => {
      setIsFocus({focus: true, positionLeft: event.target.getBoundingClientRect().offsetLeft, positionTop: event.target.getBoundingClientRect().offsetTop, selected: id})
      //console.log(event.target.children)

      //console.log(document.getElementById('btn-2022-11')?.getBoundingClientRect())
      //console.log(event.target.getBoundingClientRect())
      //if (nome) console.log(document.getElementById(nome).offsetTop)
      //console.log(event.pageX)
      //console.log(event.pageY)
    },
    [setIsFocus],
  )

  const fnBlur = useCallback(() => {
    setIsFocus({focus: false, selected: '', positionLeft: 0, positionTop: 0})
    //console.log('fez blur')
  }, [setIsFocus])

  const clickInvalido = useCallback(e => {
    e.preventDefault()
  }, [])

  const meses = useMemo(() => {
    return [
      {
        codigo: '01',
        titulo: 'Janeiro',
      },
      {
        codigo: '02',
        titulo: 'Fevereiro',
      },
      {
        codigo: '03',
        titulo: 'Março',
      },
      {
        codigo: '04',
        titulo: 'Abril',
      },
      {
        codigo: '05',
        titulo: 'Maio',
      },
      {
        codigo: '06',
        titulo: 'Junho',
      },
      {
        codigo: '07',
        titulo: 'Julho',
      },
      {
        codigo: '08',
        titulo: 'Agosto',
      },
      {
        codigo: '09',
        titulo: 'Setembro',
      },
      {
        codigo: '10',
        titulo: 'Outubro',
      },
      {
        codigo: '11',
        titulo: 'Novembro',
      },
      {
        codigo: '12',
        titulo: 'Dezembro',
      },
    ]
  }, [])

  useEffect(() => {
    getHistorico()
  }, [getHistorico])

  return (
    <Container>
      <>
        {historico &&
          Object.keys(historico)
            .reverse()
            .map((ano, index) => (
              <Periodo key={index}>
                <Ano>
                  {ano} <GoCalendar />
                </Ano>
                <Meses>
                  <>
                    {Object.values(meses).map((mes, ix) => (
                      <Mes onMouseLeave={fnBlur} key={ix} isFocus={isFocus.focus} positionLeft={isFocus.positionLeft} positionTop={isFocus.positionTop}>
                        <>
                          {historico[ano][mes.codigo] ? (
                            /* <a href={`http://www.stolthaven.com.br/arquivos/anp/${historico[ano][mes.codigo].arquivo}`} title={mes.titulo} target="_blank" rel="noreferrer">
                          {mes.titulo} <RiDownloadCloud2Line />
                        </a>  */

                            <>
                              <h1
                                onMouseEnter={event => {
                                  fnClick(`${ano}-${mes.codigo}`, event)
                                }}
                                onTouchEnd={event => {
                                  fnClick(`${ano}-${mes.codigo}`, event)
                                }}
                                className="mes">
                                <a>
                                  {mes.titulo} <RiDownloadCloud2Line />
                                </a>
                              </h1>
                              <div title={mes.titulo} key={`${ano}-${mes.codigo}`} id={`btn-${ano}-${mes.codigo}`} className={`${ano}-${mes.codigo}` == isFocus.selected ? 'selectedClass' : 'noSelected'}>
                                <a title={`Histórico dos volumes do mês de ${mes.titulo}`} href={`https://www.stolthaven.com.br/arquivos/anp/${historico[ano][mes.codigo].arquivo}`} target="_blank" rel="noreferrer opener">
                                  <BsClockHistory size={15} />
                                  Histórico dos Volumes
                                </a>
                                {`${historico[ano][mes.codigo].arquivo_xls}` != '' && `${historico[ano][mes.codigo].arquivo_xls}` != 'undefined' ? (
                                  <a title={`Tabela de movimentação do mês de ${mes.titulo}`} href={`https://www.stolthaven.com.br/arquivos/anp/${historico[ano][mes.codigo].arquivo_xls}`}>
                                    <AiOutlineTable size={20} fill="white" />
                                    Tabela de Movimentação
                                  </a>
                                ) : (
                                  <a title={`Tabela de movimentação do mês de ${mes.titulo}`} className="invalido">
                                    <BiUnlink size={20} fill="white" />
                                    Tabela de Movimentação
                                  </a>
                                )}
                              </div>
                            </>
                          ) : (
                            <h1
                              className="invalido"
                              onClick={e => {
                                clickInvalido(e)
                              }}>
                              {mes.titulo} <RiDownloadCloud2Line />
                            </h1>
                          )}
                        </>
                      </Mes>
                    ))}
                  </>
                </Meses>
              </Periodo>
            ))}
      </>
    </Container>
  )
}

export default Historico
