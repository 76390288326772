import {shade} from 'polished'
import styled, {css, keyframes} from 'styled-components'

interface Enviado {
  isSend: boolean
}

const animacao = keyframes`from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }`

export const Container = styled.section<Enviado>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  div {
    width: auto !important;
  }

  button {
    background: #041e42;
    transition: background-color 0.2s;

    ${props =>
      props.isSend &&
      css`
        opacity: 0;
        visibility: hidden;
      `}
  }

  button:hover {
    background: #f2a900 !important;
  }

  li {
    list-style-position: inside;
  }

  h1 {
    color: #041e42;
    font-size: 26px;
  }
`

export const AnimationContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  align-items: left;
  animation: ${animacao} 1s;
  margin: 20px 0px;
  padding: 0px;

  form {
    width: 100%;
    max-width: 480px;

    h1 {
      margin-bottom: 24px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: #ff9000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;

    &:hover {
      color: ${shade(0.2, '#ff9000')};
    }

    svg {
      margin-right: 16px;
    }
  }
`

export const Atencao = styled.div`
  display: flex;
  width: 80%;
  padding: 20px;
  background: #e0e0e0;
  line-height: 30px;
  border-radius: 20px;
`

export const Aguarde = styled.div<Enviado>`
  position: absolute;
  width: 560px !important;
  right: 0;
  top: 250px;
  padding: 30px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;

  ${props =>
    props.isSend &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  div {
    width: 560px !important;
    position: relative;
    padding: 16px 30px 16px 16px;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    background: #ffcdd2;
    color: #ff0000;
    font-weight: 800;
    text-align: center;
  }
`
