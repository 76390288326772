import styled from 'styled-components'
import {media} from '../../styles/media'

export const Container = styled.button`
  background: #041e42;
  border-radius: 10px;
  padding: 16px;
  color: #fff;
  font-weight: 500;
  margin-top: 16px;
  transition: background-color 0.2s;

  & :hover {
    background: #f2a900 !important;
  }

  ${media.lessThan('lg')`
    width: 100%;
  `}
`
