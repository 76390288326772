import React, {useEffect} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {FiAlertOctagon, FiCheckCircle, FiInfo, FiXCircle} from 'react-icons/fi'
import {ToastMessage, useToast} from '../../../context/Toast'
import {Container} from './styles'

interface Mensagem {
  mensagem: ToastMessage
  style: Record<string, unknown>
}

const icones = {
  info: <FiInfo size={24} />,
  success: <FiCheckCircle size={24} />,
  error: <FiAlertOctagon size={24} />,
}

const Toast: React.FC<Mensagem> = ({mensagem, style}) => {
  const {removeToast} = useToast()

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(mensagem.id)
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  }, [removeToast, mensagem.id])

  return (
    <Container type={mensagem.type || 'info'} descricao={mensagem.description == undefined ? '' : mensagem.description} style={style}>
      {icones[mensagem.type || 'info']}
      <div>
        <strong>{mensagem.title}</strong>
        {mensagem.description && <p>{ReactHtmlParser(mensagem.description)}</p>}
      </div>
      <button type="button" onClick={() => removeToast(mensagem.id)}>
        <FiXCircle size={18} />
      </button>
    </Container>
  )
}

export default Toast
