import React, {useCallback, useEffect, useMemo, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {FaAngleDoubleRight} from 'react-icons/fa'
import {Link, useParams} from 'react-router-dom'
import {animated, useTransition} from 'react-spring'
import Anp from '../../components/Anp'
import FaleConosco from '../../components/FaleConosco'
import Foot from '../../components/Foot'
import FormRequisicao from '../../components/FormRequisicao'
import Fotos from '../../components/Fotos'
import Historico from '../../components/Historico'
import AppProvider from '../../context'
import api from '../../services/api'
import servicos from '../assets/balanca2.jpg'
import comochegar from '../assets/comochegar.jpg'
import padrao from '../assets/DJI_0293.jpg'
import gestaointegradasgi from '../assets/DJI_0507.jpg'
import estrategia from '../assets/estrategia.jpg'
import stolthaven from '../assets/fachada.jpg'
import faleconosco from '../assets/faleconosco.jpg'
import historicodemovimentacoes from '../assets/historico.jpg'
import anp from '../assets/Img187.jpg'
import ideation from '../assets/img188.jpg'
import oterminal from '../assets/Img200.jpg'
import maisinformacoes from '../assets/informacoes.jpg'
import vistaaereastolthaven from '../assets/vista.jpg'
import {BreadCrumb, BreadCrumbContainer, Container, Content, Imagem, Section} from './styles'

interface Rota {
  rota: string
  origem: string
}
interface Pagina {
  [key: string]: string
}

interface Dados {
  id: number
  titulo: string
  conteudo: string
  pai: string
  index: number
  slides: Array<string>
  fotos: Record<number, unknown>
}

interface rotaFixaProps {
  rota: string
  titulo: string
  pai: string
}

const Internal: React.FC = () => {
  const {rota, origem} = useParams<Rota>()

  const rotaFixa: Array<rotaFixaProps> = useMemo(() => [], [])
  rotaFixa.push({rota: 'fale-conosco', titulo: 'Fale Conosco', pai: ''})
  //rotaFixa.push({rota: 'trabalhe-conosco', titulo: 'Trabalhe Conosco', pai: ''})
  rotaFixa.push({rota: 'requisicao-do-titular-dos-dados-pessoais', titulo: 'Requisição do Titular dos Dados Pessoais', pai: ''})
  //rotaFixa.push({rota: 'mais-informacoes', titulo: 'Mais Informações', pai: 'ANP'})

  const arrRotaFixa = useMemo((): Array<string> => {
    return rotaFixa.map(value => {
      return value.rota
    })
  }, [rotaFixa])

  const [dataContent, setDataContent] = useState<Dados>({
    id: 0,
    titulo: '',
    conteudo: '',
    pai: '',
    index: 0,
    slides: [],
    fotos: [],
  })

  //const slides = Object.values(paginas).map(element => element)

  const getContent = useCallback(
    async (rota: string, origem: string) => {
      try {
        const param = origem ? origem + '/' + rota : rota
        const response = await api.get(`conteudo.php?link=/${param}`)
        const dadosHome = response.data
        const pagina = rota.replaceAll('-', '')

        const paginas: Pagina = {
          padrao: padrao,
          stolthaven,
          servicos,
          oterminal,
          gestaointegradasgi, //gestao-integrada-sgi
          faleconosco,
          anp,
          ideation,
          comochegar,
          historicodemovimentacoes,
          maisinformacoes,
          estrategia,
          vistaaereastolthaven,
        }

        const slides = Object.values(paginas).map(element => element)

        let index = slides.findIndex(value => {
          if (paginas[pagina] == value) return true
        })

        index = index < 0 ? 0 : index

        const id = dadosHome ? dadosHome[0].id : 0

        const tituloCadastro = dadosHome ? dadosHome[0].titulo : 'Página não encontrada'

        const conteudo = dadosHome ? dadosHome[0].conteudo : 'Página não encontrada'
        const fotos = dadosHome ? dadosHome[0].fotos : []

        const fixoPai = Object.values(rotaFixa).filter(value => {
          if (value.rota == rota) {
            return value.pai
          }
        })

        const pai = dadosHome ? dadosHome[0].pai : fixoPai.length > 0 ? fixoPai[0].pai : ''

        const fixo = Object.values(rotaFixa).filter(value => {
          //console.log(rota + '===' + value.rota + '====' + value.titulo)
          if (value.rota == rota) {
            return value.titulo
          }
        })
        //console.log('fixo[0] ' + fixo.length + '======' + pai + '************' + pagina)
        const titulo = fixo.length > 0 ? fixo[0].titulo : tituloCadastro

        setDataContent({
          id,
          titulo,
          conteudo,
          index,
          slides,
          fotos,
          pai,
        })

        window.scroll({
          top: 140,
          left: 0,
          behavior: 'smooth',
        })
      } catch (e) {
        console.log('Erro ao acessar link: ' + e)
      }
    },
    [rotaFixa],
  )

  useEffect(() => {
    getContent(rota, origem)
  }, [rota, origem, getContent])

  const transitions = useTransition(dataContent.index, {
    key: dataContent.index,
    from: {opacity: 0, top: 330},
    enter: {opacity: 1, top: 0},
    leave: {opacity: 0, top: -330},
    config: {duration: 800},
  })
  // eslint-disable-next-line

  return (
    <Container>
      <Imagem>
        {transitions((style, idx) => (
          <animated.div
            className="bg"
            style={{
              ...style,
              backgroundImage: `url(${dataContent.slides[idx]})`,
            }}></animated.div>
        ))}
      </Imagem>
      <BreadCrumb>
        <BreadCrumbContainer>
          <Link to="/" title="Página Inicial">
            Home
          </Link>
          <FaAngleDoubleRight />
          {dataContent.pai != '' && (
            <React.Fragment>
              <Link to={`/${origem}`}>{dataContent.pai}</Link> <FaAngleDoubleRight />
            </React.Fragment>
          )}
          {origem && <Link to={`/${origem}/${rota}`}>{dataContent.titulo}</Link>}
          {!origem && <Link to={`/${rota}`}>{dataContent.titulo}</Link>}
        </BreadCrumbContainer>
      </BreadCrumb>
      <Content>
        <AppProvider>
          {arrRotaFixa.indexOf(rota) === -1 && dataContent && (
            <Section>
              {dataContent.pai != '' ? (
                <React.Fragment>
                  <h1>{dataContent.pai}</h1> <h2>{dataContent.titulo}</h2>
                </React.Fragment>
              ) : (
                <h1>{dataContent.titulo}</h1>
              )}

              <div>{ReactHtmlParser(dataContent.conteudo)}</div>

              {dataContent.fotos != '' && <Fotos fotos={dataContent.fotos} codigo={dataContent.id} />}

              {rota == 'mais-informacoes' && (
                <Section>
                  <Anp />
                </Section>
              )}

              {rota == 'historico-de-movimentacoes' && (
                <Section>
                  <Historico />
                </Section>
              )}
            </Section>
          )}

          {rota == 'fale-conosco' && (
            <Section>
              <FaleConosco />
            </Section>
          )}

          {rota == 'requisicao-do-titular-dos-dados-pessoais' && (
            <Section>
              <FormRequisicao />
            </Section>
          )}
        </AppProvider>
      </Content>
      <Foot />
    </Container>
  )
}

export default Internal
