import styled from 'styled-components'
import {media} from '../../styles/media'

export const Container = styled.footer`
  display: flex;
  width: 100%;
  min-height: 35px;
  color: #fff;
  background: #f2a900;
  justify-content: center;
  margin-top: 20px;

  ${media.lessThan('sm')`   
    height: auto;   
    padding:20px !important;       
 `}
`
export const Content = styled.div`
  display: flex;
  min-height: 35px;
  justify-content: space-between;
  width: 1320px;
  align-items: center;

  ${media.lessThan('sm')`
    flex-direction: column;
    height: auto;  align-items: start;  
 `}

  ${media.greaterThan('sm')`
    max-width:540px;
    padding:20px 0px 20px 0px !important;
 `}
  ${media.greaterThan('md')`
    max-width:720px
 `}
 ${media.greaterThan('lg')`
    max-width:1190px
 `}
  ${media.greaterThan('xl')`
    max-width: 1180px
 `}
 ${media.greaterThan('xxl')`
    max-width: 1320px
 `}
`

export const Termos = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  min-height: 20px;
  line-height: 20px;

  ${media.lessThan('sm')`
    flex-direction: column;
 `}

  a {
    color: #fff;
    transition: color 2s;
    display: block;
    ${media.lessThan('sm')`
    margin-top:10px;
 `}
  }

  a + a {
    margin-left: 15px;
    border-left: 2px solid #fff;
    padding-left: 15px;

    ${media.lessThan('sm')`
    border-left:none;
    margin-left: 0px;
    padding-left: 0px;
 `}
  }

  & :hover {
    color: #041e42;
  }
`
