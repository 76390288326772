import styled, {css} from 'styled-components'
import Tooltip from '../Tooltip'

interface Props {
  isFocus: boolean
  isFilled: boolean
  isErrors: boolean
}

export const Container = styled.div<Props>`
  color: #666360;
  background: #eeeeee;
  border-radius: 15px;
  border: 2px solid #e0e0e0;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  ${props =>
    props.isErrors &&
    css`
      border-color: #cc0000;
    `}

  ${props =>
    props.isFocus &&
    css`
      border-color: #041e42;
      color: #041e42;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #041e42 !important;
      font-weight: 700;
    `}
  

  & + div {
    margin-top: 20px;
  }

  input {
    flex: 1;
    background-color: #232129;
    background: transparent;
    border: 0;
    color: #424242;

    &::placeholder {
      color: #041e42;
    }
  }

  /*input[type='file'] {
    opacity: 0;
  }*/

  input[type='file']::file-selector-button {
    opacity: 0;
  }

  svg {
    margin-right: 2px;
  }
`

export const Erro = styled(Tooltip)`
  height: 20px;
  color: #cc0000;
  margin-left: 0px;
  width: 30px !important;

  svg {
    margin-right: 0px;
  }

  span {
    background: #cc0000;
    color: #fff;

    &::before {
      border-color: #cc0000 transparent;
    }
  }
`
export const Titulo = styled.span<Omit<Props, 'isFocus'>>`
  width: 0px;
  height: 18px;
  opacity: 0;
  display: flex;
  overflow: hidden;

  ${props =>
    props.isFilled &&
    css`
      display: block;
      opacity: 1;
      width: 20%;
      transition: all 2s ease;
    `}

  ${props =>
    !props.isFilled &&
    css`
      opacity: 0;
      width: 0px;
      transition: all 2s ease;
    `}

  ${props =>
    props.isErrors &&
    css`
      color: red;
    `};
`
