import React, {useEffect, useState} from 'react'
import {animated, useTransition} from 'react-spring'
import bg1 from './assets/back1.jpg'
import bg2 from './assets/back2.jpg'
import bg3 from './assets/back3.jpg'
import {Container} from './styles'

const Slide: React.FC = () => {
  const slides = [bg1, bg2, bg3]
  const [index, set] = useState(0)
  const qtd = slides.length

  const transitions = useTransition(index, {
    key: index,
    from: {opacity: 0, top: 578},
    enter: {opacity: 1, top: 0},
    leave: {opacity: 0, top: -578},
    config: {duration: 800},
  })
  useEffect(() => {
    const t = setInterval(() => set(state => (state + 1) % qtd), 10000)
    return () => clearTimeout(t)
  }, [qtd])

  return (
    <Container>
      {transitions((style, i) => (
        <animated.div
          className="bg"
          style={{
            ...style,
            backgroundImage: `url(${slides[i]})`,
          }}></animated.div>
      ))}
    </Container>
  )
}

export default Slide
