import React, {useCallback, useEffect, useState} from 'react'
import {useCookies} from 'react-cookie'
import {BiMenu} from 'react-icons/bi'
import {FaLinkedin, FaPhoneAlt} from 'react-icons/fa'
import logoStolt from '../../assets/Stolt.png'
import Menu from '../Menu'
import {Container, Content, Cookie, Head, Linkedin} from './styles'

const Header: React.FC = () => {
  const [visivel, setVisivel] = useState(false)
  const [notificacao, setNotificacao] = useState(false)
  const [cookies, setCookie] = useCookies(['notification'])

  const mostraMenu = useCallback(
    e => {
      e.preventDefault()
      const menu = !visivel ? true : false
      setVisivel(menu)
    },
    [visivel],
  )

  const fechaCookie = useCallback(() => {
    setNotificacao(true)

    const d = new Date()

    d.setTime(d.getTime() + 8 * 60 * 60 * 1000)

    const expires = d

    setCookie('notification', true, {path: '/', expires})
  }, [setNotificacao, setCookie])

  useEffect(() => {
    if (cookies.notification) setNotificacao(true)
  }, [cookies.notification])

  return (
    <Container>
      <Content>
        <a href="/" className="hamburguer" onClick={e => mostraMenu(e)}>
          <BiMenu size="40" />
        </a>
        <Head>
          <a href="/" title="Stolthaven Santos">
            <img src={logoStolt} alt={`Stolthaven Santos`} />
          </a>
          <Linkedin>
            <a title="LinkedIn Stolthaven Santos" href="https://br.linkedin.com/company/stolthaven-santos" target="_blank" rel="noreferrer">
              <FaLinkedin size={35} />
              LinkedIn Stolthaven Santos
            </a>
            <a href="tel:+55132127-8500" className="phone">
              <FaPhoneAlt size={20} />
              +55 (13) 3295-9000
            </a>
          </Linkedin>
        </Head>
        <Menu isVisivel={visivel} setVisivel={setVisivel} />
      </Content>
      <Cookie isClosed={notificacao}>
        <Content>
          <h1>Política de cookies</h1>
          <p>Para otimizar sua experiência durante a navegação, fazemos uso de cookies. Ao continuar no site, consideramos que você está de acordo com nossa Política de cookies.</p>
          <p>
            Consulte a{' '}
            <a href="/aviso-de-privacidade" title="Aviso de Privacidade">
              Aviso de Privacidade
            </a>{' '}
            para obter mais informações.
          </p>
          <button type="button" onClick={() => fechaCookie()}>
            Continuar e fechar
          </button>
        </Content>
      </Cookie>
    </Container>
  )
}
export default Header
