import {useField} from '@unform/core'
import React, {InputHTMLAttributes, useCallback, useEffect, useRef, useState} from 'react'
import {FiAlertCircle} from 'react-icons/fi'
import {Container, Erro} from './styles'

interface PropsInput extends InputHTMLAttributes<HTMLSelectElement> {
  name: string
}

const Select: React.FC<PropsInput> = ({name, children}) => {
  const {fieldName, error, registerField} = useField(name)
  const inputRef = useRef<HTMLSelectElement>(null)
  const [isFocus, setIsFocus] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const fnFocus = useCallback(() => {
    setIsFocus(true)
  }, [])

  const fnFilled = useCallback(() => {
    setIsFilled(!!inputRef.current?.value)
    setIsFocus(false)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  return (
    <Container isFocus={isFocus} isFilled={isFilled} isErrors={!!error}>
      <select ref={inputRef} onFocus={fnFocus} onBlur={fnFilled}>
        {children}
      </select>
      {error && (
        <Erro title={error}>
          <FiAlertCircle size={20} />
        </Erro>
      )}
    </Container>
  )
}

export default Select
