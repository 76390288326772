import styled, {css, keyframes} from 'styled-components'

interface Props {
  isSelected: boolean
}

const ampliar = keyframes`
  from {
    left: 50px;
  }

  to {
    left: 0;    
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Titulo = styled.div<Props>`
  width: 100%;
  padding: 15px;
  background: #041e42;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: #f2a900;
  }

  .up {
    display: none;

    ${props =>
      props.isSelected &&
      css`
        display: inline-block;
      `}
  }

  .down {
    ${props =>
      props.isSelected &&
      css`
        display: none;
      `}
  }
`

export const Descricao = styled.div<Props>`
  width: 100%;
  height: 0;
  overflow: hidden;
  padding: 20px 20px 5px 20px;
  position: relative;
  opacity: 0;
  transition: all 1s ease;

  ${props =>
    props.isSelected &&
    css`
      opacity: 1;
      height: auto;
      animation: ${ampliar} 1s forwards;
    `}
`

export const Item = styled.div`
  width: 100%;
`
