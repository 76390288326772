import React from 'react'
import {Link} from 'react-router-dom'
import {Container, Content, Termos} from './styles'

const Foot: React.FC = () => {
  return (
    <Container>
      <Content>
        <div>© 2021 Stolthaven Santos</div>
        <Termos>
          <Link to="/aviso-de-privacidade">Aviso de Privacidade</Link>
          <Link to="/termos-de-uso">Termos de uso</Link>
          <Link to="/requisicao-do-titular-dos-dados-pessoais">Requisição do Titular dos Dados Pessoais</Link>
        </Termos>
      </Content>
    </Container>
  )
}

export default Foot
