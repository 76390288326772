import React from 'react'
import {useTransition} from 'react-spring'
import {ToastMessage} from '../../context/Toast'
import {Container} from './styles'
import Toast from './Toast'

interface Messages {
  messages: ToastMessage[]
}

const ToastContainer: React.FC<Messages> = ({messages}) => {
  const msgComTransicao = useTransition(messages, {
    keys: message => message.id,
    from: {right: '-120%', opacity: 0},
    enter: {right: '0%', opacity: 1},
    leave: {right: '-120%', opacity: 1},
  })

  return (
    <Container>
      {msgComTransicao((styles, item) => (
        <Toast mensagem={item} key={item.id} style={styles} />
      ))}
    </Container>
  )
}

export default ToastContainer
