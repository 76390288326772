import React, {createContext, useCallback, useContext, useState} from 'react'
import {v4} from 'uuid'
import ToastContainer from '../components/ToastContainer'

export interface ToastMessage {
  id: string
  title: string
  description?: string
  type?: 'info' | 'error' | 'success'
}

interface Dados {
  addToast(messages: Omit<ToastMessage, 'id'>): void
  removeToast(id: string): void
}

export const ToastContext = createContext<Dados>({} as Dados)

export const ToastProvider: React.FC = ({children}) => {
  const [messages, setMessage] = useState<ToastMessage[]>([])

  const addToast = useCallback(({type, title, description}: Omit<ToastMessage, 'id'>) => {
    const id = v4()

    const toast = {
      type,
      id,
      title,
      description,
    }

    setMessage(message => [...message, toast])
  }, [])

  const removeToast = useCallback((id: string) => {
    setMessage(state => state.filter(message => message.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{addToast, removeToast}}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  )
}

export function useToast(): Dados {
  const context = useContext(ToastContext)

  if (!context) {
    throw new Error('useAuth must be used within an ToastProvider')
  }

  return context
}
