import {useEffect, useState} from 'react'
import ReactGA from 'react-ga'
import {useLocation} from 'react-router-dom'

export const useGaTracker = (): void => {
  const location = useLocation()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('UA-210689951-1')
      setInitialized(true)
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])
}

export default useGaTracker
