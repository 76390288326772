import {FaAngleDoubleRight} from 'react-icons/fa'
import styled from 'styled-components'
import {media} from '../../styles/media'
import {reactSvgComponent} from '../../utils/reactSvgComponent'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 24px !important;
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`
export const Section = styled.section`
  display: flex;
  width: 1320px;  
  margin-top: 20px;
  flex-direction: row;

  * {
    font-family: 'Montserrat', sans-serif;
  }

  h1 {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    color: #041e42;
  }

  h2 {
    margin-top: 20px !important;
    color: #041e42;
  }

  
  } 
  
  
  ${media.lessThan('sm')`
    flex-direction: column
 `}

  ${media.greaterThan('sm')`
    max-width:540px
 `}
  ${media.greaterThan('md')`
    max-width:720px
 `}
 ${media.greaterThan('lg')`
    max-width:1190px
 `}
  ${media.greaterThan('xl')`
    max-width: 1180px
 `}
 ${media.greaterThan('xxl')`
    max-width: 1320px
 `}
`

export const Bloco = styled.div`
  width: 33%;
  padding: 0px 20px 0px 20px;

  ${media.lessThan('sm')`
    width: 100%;
 `}

  h3,
  h4,
  h5,
  h6 {
    margin: 5px 0px 25px 0px;
  }

  li {
    list-style-position: inside;
    line-height: 35px;
  }

  p {
    width: 100%;
    margin: 8px 0px 8px 0px;
    line-height: 32px;

    a {
      color: #041e42;
      transition: color 2s;

      &: hover {
        color: #f2a900;
      }
    }

    a:last-child {
      margin: 20px 20px 0px 0px;
      position: relative;
      padding-right: 22px;

      ::after {
        content: url(${reactSvgComponent(FaAngleDoubleRight)});
        top: -3px;
        right: 0px;
        position: absolute;
        padding-left: 6px;
      }
    }
  }
`
