import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import Header from './components/Header'
import Routes from './Routes'
import GlobalStyle from './styles/global'

const App: React.FC = () => (
  <BrowserRouter basename="/">
    <Header />
    <Routes />
    <GlobalStyle />
  </BrowserRouter>
)

export default App
