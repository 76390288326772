import {useField} from '@unform/core'
import React, {InputHTMLAttributes, useCallback, useEffect, useRef, useState} from 'react'
import {FiAlertCircle} from 'react-icons/fi'
import {Container, Erro, Titulo} from './styles'

interface OptionsData {
  id: number
  option: string
}

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  options: OptionsData[]
}

const Radio: React.FC<PropsInput> = ({name, options, ...rest}) => {
  const {fieldName, error, registerField} = useField(name)
  const inputRef = useRef<HTMLInputElement>(null)

  const [isFilled, setIsFilled] = useState('Por meio eletrônico')

  const fnClick = useCallback(event => {
    const valor = event.currentTarget.value

    setIsFilled(valor)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return isFilled
      },
      setValue: (ref, value) => {
        ref.checked = value
      },
      clearValue: () => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        setIsFilled('Por meio eletrônico')
      },
    })
  }, [fieldName, registerField, isFilled])

  return (
    <>
      {options.map(value => (
        <Container isFilled={isFilled} isErrors={!!error} key={value.id} value={value.option}>
          <input ref={inputRef} id={`rec-${value.id}`} value={value.option} type="radio" {...rest} onChange={event => fnClick(event)} checked={isFilled == value.option} />
          <Titulo isFilled={isFilled} isErrors={!!error}>
            <label htmlFor={`rec-${value.id}`}>{value.option}</label>
          </Titulo>

          {error && (
            <Erro title={error}>
              <FiAlertCircle size={20} />
            </Erro>
          )}
        </Container>
      ))}
    </>
  )
}

export default Radio
