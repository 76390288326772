import React, {useCallback, useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import {FaArrowCircleDown, FaArrowCircleUp} from 'react-icons/fa'
import api from '../../services/api'
import {Container, Descricao, Item, Titulo} from './styles'

interface Info {
  id: number
  titulo: string
  conteudo: string
}

interface Dados {
  [anp: string]: Info
}

interface SelectedProp {
  selected: number
  height: number
}

const Anp: React.FC = () => {
  const [dados, setDados] = useState<Dados>()
  const [selected, setSelected] = useState<SelectedProp>()

  const getContent = useCallback(async () => {
    const response = await api.get(`anp.php`)
    const dados = response.data

    setDados(dados)
  }, [])

  const fnClick = useCallback((id: number, event) => {
    const position = event.target.offsetTop
    const selected = {selected: id, height: position}
    setSelected(selected)
    const idElement = event.target.id
    const elemento = document.getElementById(idElement)

    if (elemento) {
      setTimeout(function () {
        elemento.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'})
      }, 200)
    }
    //console.log('ob position ================ ' + idElement)
    /* window.scroll({
      top: position,
      left: 0,
      behavior: 'smooth',
    }) */
  }, [])

  useEffect(() => {
    getContent()
  }, [getContent])

  return (
    <Container>
      {dados &&
        Object.keys(dados).map((value, index) => (
          <Item key={index} className={selected?.selected == dados[index].id ? 'selectedClass' : 'noSelected'}>
            <Titulo
              onClick={event => {
                fnClick(dados[index].id, event)
              }}
              isSelected={selected?.selected == dados[index].id ? true : false}
              id={`titulo-${index}`}>
              {dados[index].titulo}
              <FaArrowCircleDown className="down" />
              <FaArrowCircleUp className="up" />
            </Titulo>
            <Descricao className="descricao" isSelected={selected?.selected == dados[index].id ? true : false}>
              {ReactHtmlParser(dados[index].conteudo)}
            </Descricao>
          </Item>
        ))}
    </Container>
  )
}

export default Anp
