import styled, {css} from 'styled-components'
import {media} from '../../styles/media'

interface Props {
  isFocus: boolean
  position: number
  isVisivel: boolean
}

export const Navigation = styled.nav<Props>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  transition: all 0.7s ease-in-out;
  z-index: 10;
  margin-left: 0px;

  ${props =>
    props.isVisivel &&
    css`
      margin-left: 0px;
    `}

  ${props => media.between('xs', 'lg')`
        margin-left: ${!props.isVisivel ? '-2000px' : '0px'};
        background: ${props.isVisivel ? '#041e42' : 'none'};
        margin-top: 20px;
        z-index:200;
    `}

    ${props => media.lessThan('xs')`
        margin-left: ${!props.isVisivel ? '-2000px' : '0px'};
        background: ${props.isVisivel ? '#041e42' : 'none'};
        margin-top: 20px;
        z-index:200;
    `}

  
    
  li + li {
    margin-left: 30px;
  }

  .icone {
    display: none;
    padding-right: 6px;
  }

  ${media.between('xs', 'lg')`
    flex-direction: column;
    align-items: stretch;

    ul {
      display: block;
      width: 100%;      
    }
    ul li {
      display: block;
      width: 100%;
      border-top: 1px solid #fff;      
    }
    li + li {
      margin-left: 0px !important;
    }
    .icone {
      display: inline-block;
    }
  `}

  ${media.lessThan('xs')`
    flex-direction: column;
    align-items: stretch;

    ul {
      display: block;
      width: 100%;
    }
    ul li {
      display: block;
      width: 100%;
      border-top: 1px solid #fff;
    }
    li + li {
      margin-left: 0px !important;
    }
    .icone {
      display: inline-block;
    }
  `}

  .menu {
    display: inline-block;
    height: 45px;
    line-height: 45px;
    color: #fff;
    transition: all 0.5s;

    ${media.between('xs', 'lg')`
        padding-left: 10px;      
    `}

    ${media.lessThan('xs')`
        padding-left: 10px;  
    `}

    ${media.lessThan('xl')`
      display: block;
      width: 100%;
      height: 35px;
      line-height: 35px;      
    `}

    &::after {
      transform-origin: 0% 50%;
      content: '';
      display: block;
      border-bottom: solid 3px #f2a900;
      transform: scaleX(0);
      transition: all 0.5s ease-in-out;
      padding-bottom: 5px;
    }

    &:hover {
      color: #f2a900 !important;
      transform: translateY(-5px);

      &::after {
        transform: scaleX(1);
      }
    }
  }

  .dropdown {
    z-index: 568;
    display: block;

    ${media.between('xs', 'lg')`
        padding-left: 15px;
    `}

    ${media.lessThan('xs')`
        padding-left: 15px;
    `}

    &::after {
      transform-origin: 0% 50%;
    }
    &:hover {
      & > a {
        color: #ff9900;
        transform: translateY(-5px);
        &::after {
          transform: scaleX(1);
        }
      }
      &::after {
        transform: scaleX(1);
      }
      .dropdown-content {
        opacity: 1;
        visibility: visible;

        ${props => media.greaterThan('xs')`
            opacity: ${!props.isFocus ? 0 : 1};
            visibility: ${!props.isFocus ? 'hidden' : 'visible'};           
        `}
      }
    }
  }

  .dropdown-content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.78);
    width: 100%;
    min-height: 350px;
    z-index: 560;
    left: 0;
    transition: visibility 0s, opacity 0.5s linear;
    display: flex;
    flex-direction: column;
    margin-top: 3px;

    ${props => media.greaterThan('xl')`
      padding-left:  ${props.isFocus && props.position}px;
      `}

    ${media.between('xs', 'lg')`
      opacity: 1;
      visibility: visible;
      position: relative;
      min-height: 37px !important;
      background:none;
      padding-left: 100px;  
    `}

    ${media.lessThan('xs')`
      opacity: 1;
      visibility: visible;
      position: relative;
      min-height: 37px !important;
      background:none;        
    `}

    

    a {
      color: #000;
      text-decoration: none;
      display: block;
      height: 33px;
      margin-left: 0px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${media.between('xs', 'lg')`        
        color: #fff;
        line-height: 33px;    
     `}

      ${media.lessThan('xs')`        
        color: #fff;
        line-height: 33px;    
     `}

      

        

        &:first-child {
        margin-top: 15px;
      }

      ${media.lessThan('xl')`
          margin-left: 20px;

          &:first-child {
            margin-top: 0px;
          }
      `}

      &::after {
        content: '';
        display: block;
        border-bottom: solid 3px #f2a900;
        transform: scaleX(0);
        transition: transform 1s ease-in-out;
        transform-origin: 0% 50%;
      }

      &:hover {
        transition: color 1s;
        color: #007dba !important;

        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  button {
    background: #f2a900;
    color: #041e42;
    padding: 10px;
    border-radius: 5px;
    transition: all 1s ease-in-out;
    font-size: 16px;

    ${media.lessThan('xl')`        
       margin-top:20px;
       margin-bottom:20px;
      
     `}

    &:hover {
      background: #fff;
      color: #f2a900;
    }
  }
`
