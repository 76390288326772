import styled, {css} from 'styled-components'

interface Props {
  isFocus: boolean
  positionLeft: number
  positionTop: number
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`
export const Ano = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: #f2a900;
  color: #041e42;
  font-weight: bold;
  max-width: 135px;

  svg {
    margin-left: 12px;
  }
`
export const Mes = styled.button<Props>`
  font-size: 14px;
  color: #fff;
  max-width: 180px !important;
  margin-bottom: 8px;
  margin-right: 10px;

  h1.mes {
    font-size: 14px !important;
    margin: 0px;
    width: 140px !important;
    height: 42px;

    a {
      display: flex;
      width: 140px !important;
      height: 42px;
      padding: 8px;
      background: #041e42;
      color: #fff !important;
    }

    & :hover {
      background: #f2a900 !important;
      color: #fff !important;
    }
  }
  .selectedClass {
    height: 120px;
    display: block;
    position: absolute;
    z-index: 99999;
    width: 250px !important;

    ${props =>
      props.isFocus &&
      css`
        left: ${props.positionLeft}px;
        top: ${props.positionTop}px;
      `}
  }
  .noSelected {
    height: 0px;
    width: 250px !important;
    display: none;
  }
  h1.invalido {
    background: rgba(4, 30, 66, 0.65) !important;
    cursor: not-allowed;
    font-size: 14px !important;
    padding: 8px;
    margin: 0px;
    width: 140px !important;
    height: 42px;
    color: #fff !important;
  }
  div {
    cursor: pointer;
    background: #d9d9d9;
    width: 250px !important;
    text-align: center;
    height: 120px;
    position: relative;
    padding: 6px;

    a {
      display: flex !important;
      min-width: 100% !important;
      border-bottom: 5px solid #007dba;
      color: #fff !important;
      background: #63666a;
      transition: all 0.5s ease;
      font-size: 12px;
      margin-bottom: 6px;
      justify-content: left;
      svg {
        margin-right: 10px;
        margin-left: 0px;
        fill: #fff !important;
      }
    }
    a.invalido {
      /*background: rgba(99, 102, 106, 0.55) !important;*/
      cursor: not-allowed;
      opacity: 0.25;
      /*color: #000 !important;*/
    }

    & :hover {
      background: #f2a900;
      color: #fff !important;
    }
  }

  /* Button {
    border-radius: 0px;
    min-width: 135px;
    z-index: 1;

    svg {
      margin-left: 12px;
    }
  } */

  svg {
    margin: 0px;
  }
`

/* export const Movimento = styled.div<Props>`
  max-width: 290px;
  height: 130px;
  background: #d9d9d9;
  z-index: 9999;
  display: none;
  position: absolute;

  ${props =>
    props.isFocus &&
    css`
      display: flex;
      flex-direction: column;
      left: ${props.positionLeft}px;
      top: ${props.positionTop}px;
    `}

  Button {
    background: #63666a;
    border-radius: 0px;
    text-align: left;
    border-bottom: 5px solid #007dba;
    margin: 5px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
` */

export const Meses = styled.div`
  display: flex;
  border-bottom: 8px solid #ccc;
  padding-bottom: 15px;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 960px !important;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;

  a {
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 12px;
    color: #fff;
    margin-bottom: 15px;
    margin-right: 18px;
    cursor: pointer;
    position: relative;
    text-decoration: none;

    & :hover {
      background: #f2a900 !important;
    }

    svg {
      margin-left: 12px;
    }
  }
  a.invalido {
    background: rgba(4, 30, 66, 0.65);
    cursor: not-allowed;
  }
`
export const Periodo = styled.div`
  width: 100%;
`
