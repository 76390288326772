import styled, {css} from 'styled-components'
import {media} from '../../styles/media'

interface Props {
  isClosed: boolean
}

export const Container = styled.header`
  display: flex;
  width: 100%;
  height: 143px;
  background: #041e42;
  color: #fff;
  justify-content: center;
  border-bottom: 3px solid #fff;

  img {
    max-width: 300px;
  }

  a {
    text-decoration: none;
  }

  li {
    display: inline-block;
    font-size: 1em;
    width: auto;
    z-index: 650;
  }
`

export const Cookie = styled.div<Props>`
  position: absolute;
  z-index: 90;
  width: 100%;
  min-height: 220px;
  background: #f2a900;
  top: 142px;
  display: flex;
  justify-content: center;
  color: #000000;
  padding-bottom: 20px;

  ${props =>
    props.isClosed &&
    css`
      display: none;
    `}

  div {
    padding: 10px;
  }

  h1 {
    margin: 20px 0px 10px 0px;
    color: #041e42 !important;
    font-size: 20px;
  }

  p {
    margin: 10px 0px 0px 0px !important;
    line-height: 30px;
  }

  p a {
    text-decoration: underline;
  }

  button {
    background: #f2a900;
    border-radius: 8px;
    border: 2px solid #000;
    padding: 10px;
    margin-top: 20px;
    width: fit-content;
  }
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .hamburguer {
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 5px;
    color: #fff;
    visibility: hidden;

    ${media.lessThan('lg')`
      opacity: 1;
      visibility: visible;
    `};
  }

  ${media.greaterThan('sm')`
    max-width:540px
 `}
  ${media.greaterThan('md')`
    max-width:720px
 `}
  ${media.greaterThan('lg')`
    max-width:1190px
 `}
  ${media.greaterThan('xl')`
    max-width: 1180px
 `}
 ${media.greaterThan('xxl')`
    max-width: 1320px
 `}
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;

  ${media.lessThan('sm')`
      flex-direction:column;
      justify-content: left;
  `};
`

export const Linkedin = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 13px;

  ${media.lessThan('sm')`  
      justify-content: flex-end;
   
  `};

  a {
    color: #fff;
  }

  a:first-child {
    text-indent: -4000px;
    overflow: hidden;
    width: 50px;
    height: 30px;
    position: relative;

    ${media.lessThan('sm')`        
      position: absolute;
      top: 70px;
      right: 5px;
  `};

    svg {
      top: 0px;
      right: 0px;
      position: absolute;
    }
  }
  .phone {
    height: 35px;
    display: flex;
    align-items: center;

    ${media.lessThan('sm')`        
      position: absolute;
      top: 70px;      
      left: 5px;
  `};

    svg {
      margin-right: 10px;
    }
  }
`
