import React, {useCallback, useEffect, useState} from 'react'
import ReactHtmlParser from 'react-html-parser'
import Foot from '../../components/Foot'
import Slide from '../../components/Slide'
import api from '../../services/api'
import {Bloco, Container, Content, Section} from './styles'

interface Dados {
  id: number
  titulo: string
  conteudo: string
}

interface DadosHome {
  [dadosHome: string]: Dados
}

const Main: React.FC = () => {
  const [dataContent, setDataContent] = useState<DadosHome>()

  const getContent = useCallback(async () => {
    try {
      const response = await api.get('home.php')
      const dadosHome = response.data
      setDataContent(dadosHome)
    } catch (e) {
      console.log('Erro ao acessar link: ' + e)
    }
  }, [])

  useEffect(() => {
    getContent()
  }, [getContent])

  return (
    <Container>
      <Slide />
      <Content>
        <Section>
          {dataContent && (
            <Bloco>
              <h1>{dataContent[0] && ReactHtmlParser(dataContent[0].titulo)}</h1>
              {dataContent[0] && ReactHtmlParser(dataContent[0].conteudo)}
            </Bloco>
          )}

          {dataContent && (
            <Bloco>
              <h1>{dataContent[1] && ReactHtmlParser(dataContent[1].titulo)}</h1>
              {dataContent[1] && ReactHtmlParser(dataContent[1].conteudo)}
            </Bloco>
          )}

          {dataContent && (
            <Bloco>
              <h1>{dataContent[2] && ReactHtmlParser(dataContent[2].titulo)}</h1>
              {dataContent[2] && ReactHtmlParser(dataContent[2].conteudo)}
            </Bloco>
          )}
        </Section>
      </Content>
      <Foot />
    </Container>
  )
}

export default Main
