import React from 'react'
import {Container} from './styles'

interface PropsTool {
  title: string
  className?: string
}

const Tooltip: React.FC<PropsTool> = ({title, className = '', children}) => (
  <Container className={className}>
    {children}
    <span>{title}</span>
  </Container>
)

export default Tooltip
