import {ValidationError} from 'yup'

interface retorno {
  [chave: string]: string
}

export default function getValidationErrors(err: ValidationError): retorno {
  const validacao: retorno = {}

  err.inner.forEach(erro => {
    if (erro.path) validacao[erro.path] = erro.message
  })

  return validacao
}
