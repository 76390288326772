import styled from 'styled-components'
import {media} from '../../styles/media'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  padding: 8px;
`
export const Section = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;

  * {
    font-family: 'Montserrat', sans-serif;
  }

  table,
  div,
  iframe {
    width: 100% !important;
  }

  iframe {
    max-width: 640px;
  }

  p {
    margin: 20px 0px 20px 0px;
    line-height: 26px;

    ::content {
      display: none;
    }
  }

  h1 {
    margin-bottom: 25px;
    color: #041e42;
    font-size: 28px;
  }

  h2 {
    margin-bottom: 25px;
  }

  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0px 8px 0px;
    color: #000;

    & + h4 {
      margin-top: 25px;
    }

    & + h3 {
      margin-top: 25px;
    }

    & + h5 {
      margin-top: 25px;
    }

    & + h6 {
      margin-top: 25px;
    }
  }

  li {
    line-height: 26px;
    margin: 12px 0px 12px 0px;
  }

  a {
    color: #041e42;

    transition: color 2s;

    &: hover {
      color: #f2a900;
    }
  }

  div.row {
    display: flex;

    .col {
      width: 100%;
    }
    .col-10 {
      max-width: 10%;
    }
    .col-15 {
      max-width: 15%;
    }
    .col-20 {
      max-width: 20%;
    }
    .col-25 {
      max-width: 25%;
    }
    .col-30 {
      max-width: 30%;
    }
    .col-35 {
      max-width: 35%;
    }
    .col-40 {
      max-width: 40%;
    }
    .col-45 {
      max-width: 45%;
    }
    .col-50 {
      max-width: 50%;
    }
    .col-55 {
      max-width: 55%;
    }
    .col-60 {
      max-width: 60%;
    }
    .col-65 {
      max-width: 65%;
    }
    .col-70 {
      max-width: 70%;
    }
    .col-75 {
      max-width: 75%;
    }
    .col-80 {
      max-width: 80%;
    }
    .col-85 {
      max-width: 85%;
    }
    .col-90 {
      max-width: 90%;
    }
    .col-95 {
      max-width: 95%;
    }

    .col-10,
    .col-20,
    .col-30,
    .col-40,
    .col-50,
    .col-60,
    .col-70,
    .col-80,
    .col-90,
    .col-15,
    .col-25,
    .col-35,
    .col-45,
    .col-55,
    .col-65,
    .col-75,
    .col-85,
    .col-95 {
      padding: 10px;
    }

    ${media.lessThan('lg')`
      flex-direction:column;
    `}

    .col-10,.col-20,.col-30,.col-40,.col-50,.col-60,.col-70,.col-80,.col-90,
    .col-15,.col-25,.col-35,.col-45,.col-55,.col-65,.col-75,.col-85,.col-95 {
      ${media.lessThan('lg')`
            max-width: 100%;
        `}
    }
  }

  ${media.greaterThan('sm')`
    max-width:540px;
 `}
  ${media.greaterThan('md')`
    max-width:720px;
 `}
 ${media.greaterThan('lg')`
    max-width:1190px;
 `}
  ${media.greaterThan('xl')`
    max-width: 1180px;
 `}
 ${media.greaterThan('xxl')`
    max-width: 1320px;
 `}
`
export const BreadCrumb = styled.div`
  display: flex;
  width: 100%;
  min-height: 35px;
  align-items: center;
  background: #041e42;
  justify-content: center;

  a {
    color: #fff;
  }

  svg {
    color: #fff;
    margin: 3px 12px 0px 12px;
  }
`

export const BreadCrumbContainer = styled.div`
  display: flex;
  width: 1320px;
  align-items: center;
  padding: 6px;

  ${media.greaterThan('sm')`
    max-width:540px
 `}
  ${media.greaterThan('md')`
    max-width:720px
 `}
 ${media.greaterThan('lg')`
    max-width:1190px
 `}
  ${media.greaterThan('xl')`
    max-width: 1180px
 `}
 ${media.greaterThan('xxl')`
    max-width: 1320px
 `}
`
export const Imagem = styled.div`
  display: flex;
  width: 100%;
  height: 330px;
  overflow: hidden;
  position: relative;
  background: #041e42;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 330px; /* 100vh */
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }
`
