import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`*{margin:0;
padding:0;
box-sizing:border-box;outline:0;}

body { background: #FFF; color:#424242; -webkit-font-smoothing: antialiased;font-family: 'Montserrat', sans-serif;font-size: 0.875em; /* 14px/16=0.875em // 1em is 16px*/}

img{border:0;}

button{font-family: 'Montserrat', sans-serif;font-size: 0.875em;border: none;font-weight: bold;cursor: pointer;}

.responsive {width: 100%;height: auto;}`
