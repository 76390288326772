import {useField} from '@unform/core'
import React, {InputHTMLAttributes, useCallback, useEffect, useRef, useState} from 'react'
import {FiAlertCircle} from 'react-icons/fi'
import {Container, Erro, Titulo} from './styles'

interface PropsInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string
}

const Checkbox: React.FC<PropsInput> = ({name, value, ...rest}) => {
  const {fieldName, error, registerField} = useField(name)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFilled, setIsFilled] = useState('')

  const fnFilled = useCallback(event => {
    const valor = event.currentTarget.checked ? event.currentTarget.value : ''
    setIsFilled(valor)
  }, [])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      clearValue: ref => {
        /**
         * If you want to change the default checked for false or true,
         * you can do so here. In this example, when resetting the form,
         * the checkbox goes back to its initial state.
         */
        ref.checked = false
        setIsFilled('')
      },
    })
  }, [fieldName, registerField])

  return (
    <Container isFilled={isFilled} isErrors={!!error} value={inputRef.current?.value || '-'}>
      <input ref={inputRef} type="checkbox" value={value} {...rest} onChange={event => fnFilled(event)} />

      <Titulo isFilled={isFilled} isErrors={!!error} value={inputRef.current?.value || '-'}>
        <label htmlFor={inputRef.current?.id}>{inputRef.current?.title}</label>
      </Titulo>

      {error && (
        <Erro title={error}>
          <FiAlertCircle size={20} />
        </Erro>
      )}
    </Container>
  )
}

export default Checkbox
