import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ul.fotos {
    max-width: 1020px;
  }

  ul.fotos li {
    display: inline-block;
    margin: 0px 15px 15px 0px;
  }
`
