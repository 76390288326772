import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Internal from '../pages/Internal'
import Main from '../pages/Main'
import useGaTracker from '../utils/useGaTracker'

const Routes: React.FC = () => {
  useGaTracker()

  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/:rota" exact component={Internal} />
      <Route path="/:origem/:rota" component={Internal} />
    </Switch>
  )
}

export default Routes
