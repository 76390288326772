import styled, {css} from 'styled-components'
import Tooltip from '../Tooltip'

interface Props {
  isFocus: boolean
  isFilled: boolean
  isErrors: boolean
}

export const Container = styled.div<Props>`
  color: #666360;
  background: #eeeeee;
  border-radius: 15px;
  border: 2px solid #e0e0e0;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.isErrors &&
    css`
      border-color: #cc0000;
    `}

  ${props =>
    props.isFocus &&
    css`
      border-color: #041e42;
      color: #041e42;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #041e42 !important;
      font-weight: 700;
    `}
  

  & + div {
    margin-top: 20px;
  }

  textarea {
    background-color: #232129;
    background: transparent;
    background-color: transparent;
    appearance: none;
    border: 0;
    color: #424242;
    resize: vertical;
    min-height: 150px;
    width: 100%;

    &::placeholder {
      color: #041e42;
    }
  }
  svg {
    margin-right: 16px;
  }
`

export const Erro = styled(Tooltip)`
  height: 20px;
  color: #cc0000;
  margin-left: 16px;

  svg {
    margin-right: 0px;
  }

  span {
    background: #cc0000;
    color: #fff;

    &::before {
      border-color: #cc0000 transparent;
    }
  }
`
export const Titulo = styled.div<Omit<Props, 'isFocus'>>`
  width: 100%;
  height: 0px;
  opacity: 0;
  display: block;
  overflow: hidden;
  text-align: left;

  ${props =>
    props.isFilled &&
    css`
      display: block;
      opacity: 1;
      height: 32px;
      transition: all 2s ease;
    `}

  ${props =>
    !props.isFilled &&
    css`
      opacity: 0;
      height: 0px;
      transition: all 2s ease;
    `}

  ${props =>
    props.isErrors &&
    css`
      color: red;
    `};
`
