import {FormHandles} from '@unform/core'
import {Form} from '@unform/web'
import React, {useCallback, useRef, useState} from 'react'
import * as Yup from 'yup'
import Input from '../../components/Input'
import {useToast} from '../../context/Toast'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import Button from '../Button'
import Checkbox from '../Checkbox'
import Radio from '../Radio'
import Select from '../Select'
import Textarea from '../Textarea'
import {Aguarde, AnimationContent, CheckboxGroup, Container, Recebimento} from './styles'

interface Campos {
  tx_cpf: string
  tx_mensagem: string
  tx_nome: string
  tx_email: string
  tx_telefone: string
  tx_empresa: string
  tx_identificacao: string
  tx_option: string
  tx_tipo_recebimento: string
}

interface Enviado {
  enviado: boolean
}

const FormRequisicao: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const {addToast} = useToast()
  const [enviado, SetEnviado] = useState<Enviado>({enviado: false})
  const [selectedFile, setSelectedFile] = useState<Blob>()

  const changeHandler = useCallback(
    event => {
      setSelectedFile(event.target.files[0])
    },
    [setSelectedFile],
  )

  const fnEnviaDados = useCallback(
    async ({tx_cpf, tx_mensagem, tx_nome, tx_email, tx_telefone, tx_empresa, tx_identificacao, tx_option, tx_tipo_recebimento}: Campos) => {
      const dados: Record<string, string | Blob> = {tx_cpf, tx_mensagem, tx_nome, tx_email, tx_telefone, tx_empresa, tx_identificacao, tx_option, tx_tipo_recebimento, VAR_SUBMIT: 'CONTATO'}

      /*const campos = Object.keys(dados)
      .map((key, index) => `${key}=${encodeURIComponent(dados[key])}`)
      .join('&') */

      const formData = new FormData()

      if (selectedFile) {
        formData.append('tx_foto', selectedFile)
      }

      Object.keys(dados).forEach(key => formData.append(key, dados[key]))

      const rs = await api.post('formRequisicao.php', formData)

      return rs
    },
    [selectedFile],
  )

  const fnSubimt = useCallback(
    async (data: Campos) => {
      try {
        SetEnviado({enviado: true})

        window.scroll({
          top: 750,
          left: 0,
          behavior: 'smooth',
        })

        formRef.current?.setErrors({})

        const validacao = Yup.object().shape({
          tx_email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          tx_nome: Yup.string().required('Nome obrigatório'),
          tx_telefone: Yup.string().required('Telefone obrigatório'),
          tx_foto: Yup.string().required('Foto obrigatória'),
          tx_cpf: Yup.string()
            .matches(/^[0-9]+$/, 'Somente números')
            .min(11, 'Deve conter 11 números')
            .required('CPF obrigatório'),
          tx_identificacao: Yup.string().required('Identificação obrigatório'),
        })

        if (selectedFile) {
          if (selectedFile.size > 2000000) {
            addToast({
              type: 'error',
              title: 'Erro ao enviar as informações',
              description: 'Verifique o tamanho do arquivo',
            })
            formRef.current?.setErrors({nome: 'Verifique o tamanho do arquivo'})
            throw 'Verifique o tamanho do arquivo'
          }
        }

        const dados = {
          tx_cpf: data.tx_cpf,
          tx_mensagem: data.tx_mensagem,
          tx_nome: data.tx_nome,
          tx_email: data.tx_email,
          tx_telefone: data.tx_telefone,
          tx_empresa: data.tx_empresa,
          tx_identificacao: data.tx_identificacao,
          tx_tipo_recebimento: data.tx_tipo_recebimento,
          tx_option: '',
        }

        let opcoes = ''

        for (let i = 1; i < 13; i++) {
          const campo = formRef.current?.getFieldRef('ck_option_' + i)

          if (campo.checked) opcoes += campo.value + '|'
        }

        //formRef.current?.getFieldRef('ck_option_1').clearValue()

        dados.tx_option = opcoes

        if (opcoes == '') {
          addToast({
            type: 'error',
            title: 'Erro ao enviar as informações',
            description: 'Selecione pelo menos uma opção em: Solicitações previstas na Lei Geral de Proteção de Dados',
          })

          formRef.current?.setErrors({nome: 'Selecione pelo menos uma opção'})

          throw 'Selecione pelo menos uma opção'
        }

        await validacao.validate(data, {abortEarly: false})

        const rs = await fnEnviaDados(dados)

        SetEnviado({enviado: false})

        if (rs.data.erro) {
          addToast({
            type: 'error',
            title: 'Erro ao enviar as informações',
            description: rs.data.mensagem,
          })
        } else {
          addToast({
            type: 'success',
            title: 'Dados enviados com sucesso',
            description: rs.data.mensagem,
          })

          //formRef.current?.getFieldRef('ck_option_1').clearValue()
          formRef.current?.reset()
          setSelectedFile(undefined)

          //formRef.current?.clearField('ck_option_1')
          //formRef.current?.clearField('ck_option_3')
        }
      } catch (err) {
        // if (err instanceof Yup.ValidationError)
        // eslint-disable-next-line
        //console.log(err)
        SetEnviado({enviado: false})

        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err)
          // formRef.current?.setErrors({ nome: 'errrroooooo' });
          formRef.current?.setErrors(erros)

          addToast({
            type: 'error',
            title: 'Erro ao enviar as informações',
            description: 'Verifique os dados',
          })
        }
      }

      // eslint-disable-next-line
      //console.log(data);
    },
    [addToast, SetEnviado, fnEnviaDados, selectedFile],
  )

  return (
    <Container isSend={enviado.enviado}>
      <h1>Requisição do Titular dos Dados Pessoais</h1>
      <p>A coleta dos dados pessoais de pessoas físicas, solicitados no formulário abaixo, visa, exclusivamente, permitir à equipe da Stolt, fazer contato com a pessoa responsável pelo preenchimento do respectivo formulário, a qual solicitou informações quanto aos seus dados pessoais.</p>

      <Aguarde isSend={enviado.enviado}>
        <div>Por favor, aguarde...</div>
      </Aguarde>

      <AnimationContent>
        <Form ref={formRef} onSubmit={fnSubimt}>
          <p>Preencha as informações abaixo para sua identificação em nosso banco de dados:</p>

          <Select name="tx_identificacao" title="Identificação">
            <option value="">Selecione a sua identificação (obrigatório)</option>
            <option value="Cliente">Cliente</option>
            <option value="Colaborador">Colaborador</option>
            <option value="Ex-colaborador">Ex-colaborador</option>
            <option value="Parceiro de Negócio">Parceiro de Negócio</option>
            <option value="Autoridade">Autoridade</option>
            <option value="Outros">Outros</option>
          </Select>

          <Input name="tx_nome" type="text" placeholder="Nome (obrigatório)" title="Nome" aria-autocomplete="none" autoComplete="none" maxLength={50} />

          <Input name="tx_email" type="text" maxLength={70} placeholder="E-mail (obrigatório)" title="E-mail" />

          <Input name="tx_cpf" type="text" maxLength={11} placeholder="CPF (obrigatório)" title="CPF" />

          <Input name="tx_empresa" type="text" maxLength={50} placeholder="Empresa/Departamento/Órgão Anuente" title="Empresa/Departamento/Órgão Anuente" />

          <Input name="tx_telefone" type="text" maxLength={20} placeholder="Telefone (obrigatório)" title="Telefone" />

          <p>Identifique, no quadro a seguir, o tipo de solicitação desejada, quanto aos seus dados pessoais.</p>

          <h5>Solicitações previstas na Lei Geral de Proteção de Dados</h5>

          <CheckboxGroup>
            <Checkbox name="ck_option_1" id="ck_option_1" value="Confirmação de existência de tratamento" placeholder="Confirmação de existência de tratamento" title="Confirmação de existência de tratamento" />

            <Checkbox name="ck_option_2" id="ck_option_2" value="Finalidade do tratamento" placeholder="Finalidade do tratamento" title="Finalidade do tratamento" />

            <Checkbox name="ck_option_3" id="ck_option_3" value="Acesso aos dados" placeholder="Acesso aos dados" title="Acesso aos dados" />

            <Checkbox name="ck_option_4" id="ck_option_4" value="Correção dos dados" placeholder="Correção dos dados" title="Correção dos dados" />

            <Checkbox name="ck_option_5" id="ck_option_5" value="Anonimização, bloqueio ou eliminação de dados desnecessários" placeholder="Anonimização, bloqueio ou eliminação de dados desnecessários" title="Anonimização, bloqueio ou eliminação de dados desnecessários" />

            <Checkbox name="ck_option_6" id="ck_option_6" value="Portabilidade dos dados" placeholder="Portabilidade dos dados" title="Portabilidade dos dados" />

            <Checkbox name="ck_option_7" id="ck_option_7" value="Eliminação dos dados" placeholder="Eliminação dos dados" title="Eliminação dos dados" />

            <Checkbox name="ck_option_8" id="ck_option_8" value="Informação das entidades com as quais a empresa realizou o uso compartilhado das informações" placeholder="Informação das entidades com as quais a empresa realizou o uso compartilhado das informações" title="Informação das entidades com as quais a empresa realizou o uso compartilhado das informações" />

            <Checkbox name="ck_option_9" id="ck_option_9" value="Informação sobre a possibilidade de não fornecer consentimento e suas consequências" placeholder="Informação sobre a possibilidade de não fornecer consentimento e suas consequências" title="Informação sobre a possibilidade de não fornecer consentimento e suas consequências" />

            <Checkbox name="ck_option_10" id="ck_option_10" value="Revogação do consentimento" placeholder="Revogação do consentimento" title="Revogação do consentimento" />

            <Checkbox name="ck_option_11" id="ck_option_11" value="Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais" placeholder="Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais" title="Revisão de decisões tomadas unicamente com base em tratamento automatizado de dados pessoais" />

            <Checkbox name="ck_option_12" id="ck_option_12" value="Oposição ao tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento" placeholder="Oposição ao tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento" title="Oposição ao tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento" />
          </CheckboxGroup>

          <Textarea name="tx_mensagem" placeholder="Descreva o motivo pelo qual deseja realizar esta solicitação" title="Motivo da Solicitação"></Textarea>

          <Recebimento>Recebimento da Resposta</Recebimento>

          <Radio
            name="tx_tipo_recebimento"
            options={[
              {id: 1, option: 'Por meio eletrônico'},
              {id: 2, option: 'Sob a forma impressa'},
            ]}
          />

          <p>
            Para confirmação, por favor envie uma <strong>foto</strong> (máximo de 2mb) sua <strong>com seu documento</strong> ao lado do rosto:
          </p>

          <Input
            name="tx_foto"
            type="file"
            placeholder="Anexar foto"
            title="Anexar foto"
            accept="image/png, image/jpeg"
            onChange={e => {
              changeHandler(e)
            }}
          />

          <Button type="submit">Enviar</Button>
        </Form>
      </AnimationContent>
    </Container>
  )
}

export default FormRequisicao
