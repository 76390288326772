import styled, {css} from 'styled-components'
import Tooltip from '../Tooltip'

interface Props {
  isFilled: string
  isErrors: boolean
  value: string
}

export const Container = styled.div<Props>`
  color: #666360;
  background: #eeeeee;
  border-radius: 15px;
  border: 2px solid #e0e0e0;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  ${props =>
    props.isErrors &&
    css`
      border-color: #cc0000;
    `}

  ${props =>
    props.isFilled == props.value &&
    css`
      color: #041e42 !important;
      font-weight: 700;
      border-color: #041e42;
    `}
  

  & + div {
    margin-top: 20px;
  }

  input {
    background-color: #232129;
    background: transparent;
    color: #424242;
    margin-right: 20px;

    &::placeholder {
      color: #041e42;
    }
  }

  label {
    cursor: pointer;
  }

  svg {
    margin-right: 2px;
  }
`

export const Erro = styled(Tooltip)`
  height: 20px;
  color: #cc0000;
  margin-left: 0px;
  width: 30px !important;

  svg {
    margin-right: 0px;
  }

  span {
    background: #cc0000;
    color: #fff;

    &::before {
      border-color: #cc0000 transparent;
    }
  }
`
export const Titulo = styled.span<Omit<Props, 'value'>>`
  height: 18px;
  display: flex;
  overflow: hidden;

  ${props =>
    props.isErrors &&
    css`
      color: red;
    `};
`
