import axios from 'axios'

const api = axios.create({
  baseURL: 'https://www.stolthaven.com.br/json',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export default api
