import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react'
import {FaAngleRight} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import api from '../../services/api'
import {Navigation} from './styles'

interface ItemMenu {
  focus: boolean
  position: number
}

interface SubMenu {
  titulo: string
  link: string
}

interface ItemDados {
  titulo: string
  link: string
  sub: Array<SubMenu>
}

interface Dados {
  [menu: string]: ItemDados
  /* [menu: string]: Array<{
    titulo: string
    link: string
    sub: []
  }> */
  // [menu: string]: ItemDados -> funfa ok
  // [menu: string]: Record<string, ItemDados> -> funfa ok
  //menu: [Record<string, unknown>]
  //Record<string, unknown>
  //Array<ItemDados>
  //menu: Record<Array, unknown>
}

interface VisivelMenu {
  isVisivel: boolean
  setVisivel: Dispatch<SetStateAction<boolean>>
}

const Menu: React.FC<VisivelMenu> = isVisivel => {
  const [dataMenu, setDataMenu] = useState<Dados>()

  const [isFocus, setIsFocus] = useState<ItemMenu>({focus: false, position: 0})

  const fnWebstolt = useCallback(() => {
    window.location.href = '/webstolt'
  }, [])

  const fnFocus = useCallback(
    event => {
      setIsFocus({focus: true, position: event.target.offsetLeft})
      //console.log(event.target.children)
      //console.log(event)
      //console.log(event.target.offsetLeft)
    },
    [setIsFocus],
  )

  const fnBlur = useCallback(() => {
    setIsFocus({focus: false, position: 0})
    //console.log('fez blur')
  }, [setIsFocus])

  const fnClick = useCallback(() => {
    isVisivel.setVisivel(false)
    setIsFocus({focus: false, position: 0})
    //console.log('fez click menu')
  }, [isVisivel, setIsFocus])

  const getMenu = useCallback(async () => {
    try {
      const response = await api.get('menu.php')
      const menu = response.data.menu
      setDataMenu(menu)
    } catch (e) {
      //console.log(e)
    }
  }, [setDataMenu])

  useEffect(() => {
    /* const getMenu = async () => {
      try {
        const response = await api.get('menu.php')
        const menu = response.data.menu
        setDataMenu(menu)
      } catch (e) {
        console.log(e)
      }
    } */

    getMenu()
  }, [getMenu])

  return (
    <Navigation isFocus={isFocus.focus} position={isFocus.position} isVisivel={isVisivel.isVisivel}>
      <ul>
        {dataMenu &&
          Object.keys(dataMenu).map(idx =>
            dataMenu[idx].sub.length === 0 ? (
              <li key={idx}>
                <Link to={dataMenu[idx].link} title={dataMenu[idx].titulo} className="menu" onClick={fnClick}>
                  {dataMenu[idx].titulo}
                </Link>
              </li>
            ) : (
              <li key={idx}>
                <div className="dropdown" onMouseEnter={fnFocus} onMouseLeave={fnBlur}>
                  <Link to={dataMenu[idx].link} title={dataMenu[idx].titulo} className="menu" onClick={fnClick}>
                    {dataMenu[idx].titulo}
                  </Link>
                  <div className="dropdown-content">
                    {dataMenu[idx].sub.length > 0 &&
                      dataMenu[idx].sub.map(key => (
                        <Link to={key.link} title={key.titulo} key={Math.random()} onClick={fnClick}>
                          <FaAngleRight className="icone" />
                          {key.titulo}
                        </Link>
                      ))}
                  </div>
                </div>
              </li>
            ),
          )}

        <li>
          <a href="https://eclo.fa.em2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/" title="Trabalhe Conosco" className="menu" target="_blank" rel="noreferrer">
            Trabalhe Conosco
          </a>
        </li>
        <li>
          <Link to="/como-chegar" title="Como Chegar" className="menu" onClick={fnClick}>
            Como Chegar
          </Link>
        </li>
        <li>
          <Link to="/fale-conosco" title="Fale Conosco" className="menu" onClick={fnClick}>
            Fale Conosco
          </Link>
        </li>
      </ul>
      <button type="button" onClick={fnWebstolt}>
        WebStolt
      </button>
    </Navigation>
  )
}
export default Menu
