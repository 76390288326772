import {FormHandles} from '@unform/core'
import {Form} from '@unform/web'
import React, {useCallback, useRef, useState} from 'react'
import * as Yup from 'yup'
import Input from '../../components/Input'
import {useToast} from '../../context/Toast'
import api from '../../services/api'
import getValidationErrors from '../../utils/getValidationErrors'
import Button from '../Button'
import Select from '../Select'
import Textarea from '../Textarea'
import {Aguarde, AnimationContent, Atencao, Container} from './styles'

interface Campos {
  tx_site: string
  tx_assunto: string
  tx_mensagem: string
  tx_nome: string
  tx_email: string
  tx_telefone: string
  tx_empresa: string
  tx_departamento: string
}

interface Enviado {
  enviado: boolean
}

const FaleConosco: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const {addToast} = useToast()
  const [enviado, SetEnviado] = useState<Enviado>({enviado: false})

  const fnEnviaDados = useCallback(async ({tx_site, tx_assunto, tx_mensagem, tx_nome, tx_email, tx_telefone, tx_empresa, tx_departamento}: Campos) => {
    const dados: Record<string, string | Blob> = {tx_site, tx_assunto, tx_mensagem, tx_nome, tx_email, tx_telefone, tx_empresa, tx_departamento, VAR_SUBMIT: 'CONTATO'}

    /*const campos = Object.keys(dados)
      .map((key, index) => `${key}=${encodeURIComponent(dados[key])}`)
      .join('&') */

    const formData = new FormData()

    Object.keys(dados).forEach(key => formData.append(key, dados[key]))

    const rs = await api.post('contato.php', formData)

    return rs
  }, [])

  const fnSubimt = useCallback(
    async (data: Campos) => {
      try {
        SetEnviado({enviado: true})

        window.scroll({
          top: 750,
          left: 0,
          behavior: 'smooth',
        })

        formRef.current?.setErrors({})

        const validacao = Yup.object().shape({
          tx_email: Yup.string().required('E-mail obrigatório').email('Digite um e-mail válido'),
          tx_nome: Yup.string().required('Nome obrigatório'),
          tx_departamento: Yup.string().required('Departamento obrigatório'),
        })

        await validacao.validate(data, {abortEarly: false})

        const dados = {
          tx_site: data.tx_site,
          tx_assunto: data.tx_assunto,
          tx_mensagem: data.tx_mensagem,
          tx_nome: data.tx_nome,
          tx_email: data.tx_email,
          tx_telefone: data.tx_telefone,
          tx_empresa: data.tx_empresa,
          tx_departamento: data.tx_departamento,
        }

        const rs = await fnEnviaDados(dados)

        SetEnviado({enviado: false})

        if (rs.data.erro) {
          addToast({
            type: 'error',
            title: 'Erro ao enviar as informações',
            description: rs.data.mensagem,
          })
        } else {
          addToast({
            type: 'success',
            title: 'Dados enviados com sucesso',
            description: rs.data.mensagem,
          })

          formRef.current?.reset()
        }
      } catch (err) {
        // if (err instanceof Yup.ValidationError)
        // eslint-disable-next-line
        //console.log(err)

        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err)
          // formRef.current?.setErrors({ nome: 'errrroooooo' });
          formRef.current?.setErrors(erros)

          SetEnviado({enviado: false})

          addToast({
            type: 'error',
            title: 'Erro ao enviar as informações',
            description: 'Verifique os dados',
          })
        }
      }

      // eslint-disable-next-line
      //console.log(data);
    },
    [addToast, SetEnviado, fnEnviaDados],
  )

  return (
    <Container isSend={enviado.enviado}>
      <h1>Fale Conosco</h1>
      <p>Para entrar em contato conosco, preencha o formulário abaixo:</p>
      <p>Antes de enviar sua mensagem, observe as funções de cada departamento. A correta seleção da área, agilizará as respostas.</p>

      <Aguarde isSend={enviado.enviado}>
        <div>Por favor, aguarde...</div>
      </Aguarde>

      <ul>
        <li>
          <strong>ATENDIMENTO À CLIENTES</strong> – Para dúvidas, problemas ou sugestões de clientes da Stolthaven.
        </li>
        <li>
          <strong>COMERCIAL</strong> – Locação e serviços de armazenagem de granéis líquidos.
        </li>
        <li>
          <strong>GERÊNCIA E MARKETING</strong> – Atendimento à solicitações em geral, veículos de imprensa, e promotores de ações de marketing e endomarketing.
        </li>
        <li>
          <strong>OPERAÇÕES</strong> – Informações técnicas sobre armazenagem de líquidos.
        </li>
        <li>
          <strong>SHEQ</strong> – Assuntos de segurança e o meio ambiente.
        </li>
        <li>
          <strong>FINANCEIRO</strong> – Informações sobre a nossa área contábil.
        </li>
        <li>
          <strong>FORNECEDORES</strong> – Para fornecer produtos ou serviços à Stolthaven.
        </li>
      </ul>

      <AnimationContent>
        <Form ref={formRef} onSubmit={fnSubimt}>
          <Input name="tx_nome" type="text" placeholder="Nome (obrigatório)" title="Nome" aria-autocomplete="none" autoComplete="none" maxLength={50} />

          <Input name="tx_email" type="text" maxLength={70} placeholder="E-mail (obrigatório)" title="E-mail" />

          <Input name="tx_empresa" type="text" maxLength={70} placeholder="Empresa" title="Empresa" />

          <Input name="tx_site" type="text" maxLength={70} placeholder="Site" title="Site" />

          <Input name="tx_telefone" type="text" maxLength={20} placeholder="Telefone" title="Telefone" />

          <p>Com qual departamento deseja falar? (obrigatório)</p>

          <Select name="tx_departamento" title="Departamento">
            <option value="">Selecione</option>
            <option value="Atendimento à Clientes">Atendimento à Clientes</option>
            <option value="Comercial">Comercial</option>
            <option value="Gerência e Marketing">Gerência e Marketing</option>
            <option value="Operações">Operações</option>
            <option value="SHEQ">SHEQ</option>
            <option value="Financeiro">Financeiro</option>
            <option value="Fornecedores">Fornecedores</option>
          </Select>

          <Input name="tx_assunto" type="text" maxLength={50} placeholder="Assunto" title="Assunto" />

          <Textarea name="tx_mensagem" placeholder="Mensagem" title="Mensagem"></Textarea>

          <Button type="submit">Enviar</Button>
        </Form>
      </AnimationContent>

      <Atencao>A coleta dos dados pessoais de pessoas físicas, solicitados no formulário acima, visa, exclusivamente, permitir à equipe da Stolthaven, fazer contato com a pessoa responsável pelo preenchimento do respectivo formulário, a qual manifestou interesse no estabelecimento de comunicação com a empresa.</Atencao>
    </Container>
  )
}

export default FaleConosco
