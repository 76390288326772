import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 578px;
  overflow: hidden;
  position: relative;
  background: #041e42;

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 578px; /* 100vh */
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }
`
